body {
  min-height: 100% !important;
}

.app-header {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  /* Set the background color to white */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Add a drop shadow */
  z-index: 3000;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.header-right button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.app-logo {
  width: 150px;
  height: auto;
}

.app-content {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-info {
  z-index: 1000;
}

.app-info .app-info-loading {
  text-align: center;
  font-size: 15px;
  padding: 5px 0 5px;
  background-color: rgba(200, 200, 200, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.app-info .app-error-loading {
  text-align: center;
  font-size: 15px;
  padding: 5px 0 5px;
  background-color: rgba(168, 24, 24, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.app-info .app-alert-loading {
  text-align: center;
  font-size: 15px;
  padding: 5px 0 5px;
  background-color: rgba(239, 242, 45, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.top-title {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  color: #666666;
}

@media only screen and (max-width: 1096px) {
  .top-title {
    position: relative;
    h1, a {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 670px) {
  #menu-movidesk {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .header-right {
    display: none;
  }
}
