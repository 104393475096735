.notificacao {
  justify-content: center;
  display: flex;
  text-align: center;
  margin-top: 30px;
  width: 800px;
  background: #fff;
  color: #6C7381;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0px 4px 7px 0px #0000001A;
}

h4 {
  margin: 0;
}